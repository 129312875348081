import { TextVariant } from "@punks/core"
import classNames from "classnames"
import React from "react"
import Typography from "../../../atoms/Typography"
import ConditionalLink from "../../../utilities/ConditionalLink"
import classes from "./ProductLabel.module.css"

interface Classes {
  title?: string
  subtitle?: string
}

export interface ProductLabelProps {
  productLink?: string
  title: React.ReactNode
  titleLink?: string,
  titleVariant?: TextVariant
  subtitle: React.ReactNode
  subtitleVariant?: TextVariant
  category?: React.ReactNode
  ellipses?: boolean
  ellipsesCategory?: boolean
  mobileCenter?: boolean
  className?: string
  classes?: Classes
  children?: any
}

const ProductLabel = ({
  title,
  titleLink,
  productLink,
  titleVariant,
  subtitle,
  subtitleVariant,
  category,
  ellipses,
  ellipsesCategory,
  mobileCenter,
  className,
  classes: userClasses,
  children,
}: ProductLabelProps) => {
  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.mobileCenter]: mobileCenter,
        },
        className
      )}
    >
      <ConditionalLink
        link={titleLink}
      >
        <Typography
          variant={titleVariant ?? "subtitle2"}
          component="p"
          textClassName={`${classes.title} ${userClasses?.title}`}
        >
          {title}
        </Typography>
      </ConditionalLink>
      <ConditionalLink
        prefetch={false}
        link={productLink}
        className="no-underline"
      >
        <Typography
          variant={subtitleVariant ?? "body1"}
          weight="light"
          textClassName={classNames(
            classes.subtitle,
            {
              [classes.ellipses]: ellipses,
              [classes.fillSubtitleHeight]: !ellipses,
            },
            userClasses?.subtitle
          )}
        >
          {subtitle}
        </Typography>
      </ConditionalLink>
      {category && (
        <Typography
          variant="body1"
          weight="light"
          textClassName={classNames(classes.category, {
            [classes.ellipses]: ellipsesCategory,
          })}
        >
          {category}
        </Typography>
      )}
      {children}
    </div>
  )
}

ProductLabel.defaultProps = {
  ellipses: true,
  ellipsesCategory: true,
}

export default ProductLabel
